<template>
	<main class="layer-popup-container">
		<div class="layer-popup layer-popup-live">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">판로TV 라이브</h3>
						<i class="video-badge video-badge-live"></i>
						<button type="button" class="btn-close" @click="clickClose">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" data-fill="true">
						<!-- VOD 제목 -->
						<div class="broadcast-vod-header">
							<!--<p class="broadcast-vod-header__cate">[이커머스 입점설명회]</p>-->
							<div class="broadcast-vod-header__title">
								<p class="title">
									{{ brcTit }}
								</p>
								<span class="date">{{ broadcastTime }}</span>
							</div>
						</div>
						<!-- //VOD 제목 -->

						<div class="popup-live-flex">
							<div class="flex-l">
								<!-- VOD player -->
								<div class="vod-wrap" id="vodWrap">
									<iframe name="myplayer" id="myplayer" :src="playerUrl" frameborder="0" width="600" height="400" allow="encrypted-media; gyroscope; picture-in-picture;autoplay;" allowfullscreen></iframe>
								</div>
								<!-- //VOD player -->

								<!-- VOD 도구 -->
								<div class="broadcast-vod-tools">
									<button type="button" class="button" v-if="isMobile" :class="{ 'is-active': liveFull }" @click="toggleLiveFull">
										<i class="icon icon-vod-full"></i>
									</button>

									<button type="button" class="button" v-if="isMobile" v-show="liveFull" :class="{ 'is-active': soundOn }" @click="toggleSound">
										<i class="icon icon-vod-soundoff"></i>
									</button>

									<button type="button" class="button" v-show="liveFull" :class="{ likeVod: liked }" @click="addRecommCnt">
										<i class="icon icon-vod-like"></i>
										<span class="text">좋아요</span>
										<span class="nums">{{ recommCnt }}</span>
									</button>
									<button type="button" class="button" v-show="liveFull" @click="copyUrl">
										<i class="icon icon-vod-share"></i>
										<input type="text" id="urlInput" readonly style="position: absolute; left: -10000px" />
										<span class="text">공유</span>
									</button>
									<a v-if="relLink" v-show="liveFull" :href="relLink" target="_blank" title="새창 열림" class="button">
										<i class="icon icon-vod-link"></i>
										<span class="text">관련페이지</span>
									</a>
									<button v-if="useChatYn == 'Y'" v-show="liveFull" type="button" class="button" @click="chatToggle">
										<i class="icon icon-vod-comment"></i>
										<span class="text" id="chatToggleBtn">댓글숨기기</span>
									</button>
								</div>
								<!-- //VOD 도구 -->
								<!-- VOD 공지 -->
								<div class="broadcast-vod-notice" v-show="liveFull" v-html="notice"></div>
								<!-- //VOD 공지 -->
							</div>
							<div id="chatDiv" v-show="liveFull" class="livechat-container">
								<!-- 채팅공지사항 -->
								<div class="chat-notice-wrap" id="chatNoticeWrap" style="display: none">
									<div class="chat-notice">
										<i class="icon-pin"></i>
										<span id="chatNoti"></span>
									</div>
								</div>
								<div class="livechat-content" id="livechat-content">
									<div id="chatHistory">
										<div class="msg" v-if="isMobile" v-for="idx in 10" :key="idx">&nbsp;</div>
									</div>
								</div>
								<div v-if="!isAuth" class="livechat-login">
									<button @click="login" class="button-chat-login">
										<span class="text">로그인</span>
									</button>
								</div>
								<div class="livechat-footer" v-if="isAuth" id="livechat-footer">
									<div class="profile">
										<i class="thumb-wrap">
											<img v-if="session.profileImg" :src="session.profileImg" alt="사진" />
											<img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="사진" />
										</i>
										<span class="nickname">{{ session.lgnId }}</span>
									</div>
									<form @submit.prevent="sendMsg">
										<div class="livechat-footer-form">
											<input type="text" id="chatMsg" placeholder="채팅 내용을 입력하세요" class="input" />
											<button type="submit" class="livechat-footer-submit">
												<i class="icon-livechat-send"></i>
											</button>
											<!-- <input type="text" id="chatMsg" placeholder="채팅 내용을 입력하세요" @keyup.enter="sendMsg" class="input" /> -->
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<style>
.likeVod {
	background-color: red;
}
.chatShow {
	background-color: green;
}
</style>
<script>
import { mapGetters } from 'vuex';
import { ACT_GET_BRC_INFO, ACT_INSERT_RECOMM_CNT } from '../../store/_act_consts';
import { MUT_CLOSE_BROADCAST_LIVE_MODAL, MUT_SET_RETURN_ROUTE, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SET_RETURN_BRC_INFO_NO } from '../../store/_mut_consts';
const jsonp = require('jsonp');

let sdk, controller, config;
let isChatShow = true; //채팅창 표시 여부
let chatHideCnt = 0; //채팅창 숨기기중 올라온 채팅수
let defaultProfileImg; //채팅창 기본 프로필 이미지
export default {
	name: 'BroadcastLiveModal',
	props: {
		brcInfoNo: Number,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal']),
		...mapGetters('broadcast', ['broadcastLive', 'returnBrcInfoNo']),
		...mapGetters('common', ['isMobile']),
	},
	mounted() {
		this.getClientIP();
		//window.addEventListener('message', this.receiveMessage);
		const vgControllerScript = document.createElement('script');
		vgControllerScript.setAttribute('src', 'https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js');
		document.head.appendChild(vgControllerScript);

		const kollusChattingSDKScript = document.createElement('script');
		kollusChattingSDKScript.setAttribute('src', 'https://file.kollus.com/kollusChatting/sdk/KOLLUS_CHATTING_SDK1.1.2.js');
		document.head.appendChild(kollusChattingSDKScript);
		console.log('mounted!!!');
		this.initVgController();

		// 모바일 접속시 주소창 숨기기
		scrollTo(0, 0, 1);

		isChatShow = true;
	},
	data: () => ({
		brcTit: '', //방송제목
		playerUrl: '', //라이브 방송 iframe url
		res: new Object(),
		msgObj: new Object(),
		soundOn: false, // 소리출력 옵션
		liveFull: true, // 전체화면 옵션
		liked: false, //좋아요 여부
		likeCnt: 0, //서버로 전송할 좋아요 갯수
		recommCnt: 0, //서버로 부터 내려받은 좋아요 갯수
		relLink: '', //관련페이지 링크
		title: '', //관련페이지 타이틀
		notice: '', //방송설명
		broadcastTime: '', //방송시간
		useChatYn: 'Y', //시스템 채팅가능여부
		isChatBlock: false, //사용자 채팅차단 여부
		brcEndYn : false,    //방송종료여부
		clientIp: '0.0.0.0',
	}),
	created() {
		console.log('created!!!');
		console.log('brcInfoNo : ' + this.brcInfoNo);
		if (!this.brcInfoNo) this.clickClose();
		this.getBrcInfo();
		if(!this.$cookies.get('gachi-brodcast-noneuser')){
			this.$cookies.set('gachi-brodcast-noneuser', this.generateRandomString(8), '1d');
		}
	},
	beforeDestroy() {
		if (sdk) {
			sdk.disposeConnection();
			sdk.off('join');
			sdk.off('chat');
			sdk.off('connect_status');
		}
		//window.addEventListener()
	},
	methods: {
		login() {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: '회원가입 및 로그인이 필요합니다.',
				html: true,
				showCancelButton: true,
				yesfunc: () => {
					this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
					this.$store.commit(`broadcast/${MUT_SET_RETURN_BRC_INFO_NO}`, this.brcInfoNo);
					this.$router.push({ name: 'Login' });
				},
			});
		},
		dateDayWeek: function (item) {
			const week = ['일', '월', '화', '수', '목', '금', '토'];
			var date = new Date(item);
			return week[date.getDay()];
		},
		getClientIP(){
			jsonp('https://jsonip.com/', null, (err, data) => {
				if (err) {
					//console.error(err.message);
					this.clientIp = '1.1.1.1';
				} else {
					//console.log(data);
					this.clientIp = data.ip;
				}
			});
		},
		generateRandomString(num) {
			const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
			let result = '';
			const charactersLength = characters.length;
			for (let i = 0; i < num; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}

			return result;
		},
		getBrcInfo() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
					brcInfoNo: this.brcInfoNo,
					brcTyp: 2,
				})
				.then((res) => {
					let info = res.data.VIEW;
					if (info.BRC_STS_CD > 10) {
						alert('종료된 방송입니다.');
						this.clickClose();
					}
					this.playerUrl = info.PLAYER_URL;
					this.notice = info.noties[0].NTC_CASE_CN.replaceAll(/(\n|\r\n)/g, '<br>');
					this.brcTit = info.BRC_TIT;
					if (info.links[0]) this.relLink = info.links[0].REL_LINK;
					if (info.links[0]) this.title = info.links[0].TITLE;
					this.recommCnt = info.RECOMM_CNT;
					this.broadcastTime = info.BRC_SCHDG_BGNG_DT.slice(0, 4) + '.' + info.BRC_SCHDG_BGNG_DT.slice(6, 7) + '.' + info.BRC_SCHDG_BGNG_DT.slice(8, 10) + ' (' + this.dateDayWeek(info.BRC_SCHDG_BGNG_DT) + ') ' + info.BRC_SCHDG_BGNG_DT.slice(11, 16) + ' ~ ' + info.BRC_SCHDG_END_DT.slice(11, 16);
					defaultProfileImg = require('../../assets/img/community/reply_unknown_icon.svg');
					this.useChatYn = info.USE_CHAT_YN;

					if (this.useChatYn == 'N') {
						this.chatToggle();
					}

					if (info.DCLR_TYP_CD && info.DCLR_TYP_CD == '01') {
						//채팅차단
						this.isChatBlock = true;
					}

					if (info.DCLR_TYP_CD && info.DCLR_TYP_CD == '02') {
						//시청차단
						alert('시청이 차단되었습니다.');
						this.clickClose();
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		//방송상태조회
		async getBrcSts() {
			await this.$store
				.dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
					brcInfoNo: this.brcInfoNo,
					recommCnt: 0,
					clientIP: this.clientIp,
					anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
				})
				.then((res) => {
					console.log('sts : ' + JSON.stringify(res));
					let sts = res.data.VIEW;
					if(!this.brcEndYn && sts.BRC_STS_CD > 10){
						this.brcEndYn = true;

						// title: '판로TV 방송이 종료되었습니다.<br/>방송일로부터 약 한 주 뒤에 프로그램별 VOD 다시 보기가 가능합니다.',
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {							
							title: '방송이 종료되었습니다.<br/>VOD는 금일 중으로 업로드 될 예정이며,<br/>판로TV 페이지에서 확인하실 수 있습니다.',
							html: true,
							yesfunc: this.clickClose()
						});
					}
					this.recommCnt = sts.RECOMM_CNT;
					if (sts.BLOCK_TYP_CD == '01') {
						//  01:시청차단 ,02:채팅차단
						this.isChatBlock = true;
					} else if (sts.BLOCK_TYP_CD == '02') {
						controller.mute();
						controller.pause();
						alert('시청이 차단되었습니다.');
						this.clickClose();
					} else if (sts.BLOCK_TYP_CD == '') {
						this.isChatBlock = false;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		// Video Gateway Controller Initializing
		initVgController() {
			console.log('initVgController Start');
			let ref = this;
			controller = new VgControllerClient({
				target_window: document.querySelector('#myplayer').contentWindow,
			});

			// Ready Listener
			// 자세한 설명은 https://catenoid-support.atlassian.net/wiki/spaces/SUP/pages/3312250/V+G+Controller 를 참고하세요.
			controller.on('ready', function () {
				console.log('vg controller ready');
				controller.set_volume(50); // 볼륨 크기
				controller.mute(); // 음소거
				controller.play(); // 영상 재생
				console.log('KOLLUS_CHATTING_SDK', KOLLUS_CHATTING_SDK);
				try {
					sdk = KOLLUS_CHATTING_SDK.getKollusSDK(controller);
					sdk.startConnection();
					console.log('sdk', sdk);

					config = sdk.getConfig();

					// SDK Object - Event 등록
					sdk.on('join', function (msg) {
						// Event Result - 최대 30개의 최근 메시지 표시
						if (msg.length > 0) {
							for (var i = 0; i < msg.length; i++) {
								console.log(msg[i]);
								ref.showChatMessage('chat', msg[i], config);
							}
						}
					});

					// SDK Object - Event 등록
					sdk.on('chat', function (msg) {
						console.log('msg : ' + JSON.stringify(msg));

						if (isChatShow) {
							chatHideCnt = 0;
							document.getElementById('chatToggleBtn').innerText = `댓글숨기기`;
						} else {
							chatHideCnt += 1;
							document.getElementById('chatToggleBtn').innerText = `댓글표시(${chatHideCnt})`;
						}

						ref.showChatMessage('chat', msg, config);
					});

					// SDK Object - Event 등록
					sdk.on('blocked', function (userId) {
						console.log('Blocked: ' + userId);
					});

					// SDK Object - Event 등록
					sdk.on('connect_status', function (status, retryCount) {
						console.log('connect_status : ' + JSON.stringify(status));
						console.log('connect_retryCount : ' + JSON.stringify(retryCount));
						const connect_available = status === 'disconnected' || status === 'disconnected_exceed';
						const disconnect_available = !connect_available;

						if (status === 'disconnected' || status === 'disconnected_exceed') {
						}
					});

					// SDK Object - Event 등록
					sdk.on('status', function (status) {
						console.log('status : ' + JSON.stringify(status));
						ref.getBrcSts();
					});
				} catch (e) {
					console.log('e : ' + JSON.stringify(e));
					if (e == 'No chatting information') {
						alert('종료된 방송입니다. ');
					}
				}
			});

			controller.on('error', function (error_code) {
				console.log('error : ' + error_code);
			});

			controller.on('pause', function () {
				console.log('pause event');
				controller.play();
			});

			controller.on('muted', function (is_muted) {
				ref.soundOn = !is_muted;
			});
		},
		sendMsg() {
			if (this.isChatBlock) {
				alert('채팅이 차단되었습니다.');
				return false;
			}
			this.msgObj.message = sdk.filterText(document.getElementById('chatMsg').value); // 욕을 *로 표시하기
			this.msgObj.type = '01';
			this.msgObj.adminYn = 'N'; // 관리자 여부
			this.msgObj.profileImg = this.session.profileImg;

			console.log('msgObj : ' + JSON.stringify(this.msgObj));
			console.log('config.settings : ' + JSON.stringify(config.settings));

			sdk.sendMessage(JSON.stringify(this.msgObj));
			document.getElementById('chatMsg').value = '';
			if (this.isMobile) {
				isChatShow = false;
				document.getElementById('livechat-footer').style.display = 'none';
			}
		},
		clickClose() {
			this.$store.commit(`broadcast/${MUT_CLOSE_BROADCAST_LIVE_MODAL}`);
			this.$store.commit(`broadcast/${MUT_SET_RETURN_BRC_INFO_NO}`, null);
		},
		//전체화면 토글
		toggleScreen() {
			var docV = document.documentElement;

			if (docV.requestFullscreen) docV.requestFullscreen();
			else if (docV.webkitRequestFullscreen)
				// Chrome, Safari (webkit)
				docV.webkitRequestFullscreen();
			else if (docV.mozRequestFullScreen)
				// Firefox
				docV.mozRequestFullScreen();
			else if (docV.msRequestFullscreen)
				// IE or Edge
				docV.msRequestFullscreen();

			controller.set_screen();
		},
		// 전체화면 토글
		toggleLiveFull() {
			this.liveFull = !this.liveFull;
		},
		// 소리 옵션 토글
		toggleSound() {
			this.soundOn = !this.soundOn;
			controller.mute();
			controller.play();
		},
		//좋아요 이벤트
		addRecommCnt() {
			if (!this.liked) {
				//좋아요 버튼 누를시
				this.likeCnt = 1;
				this.liked = true;
			} else {
				//좋아요 버튼 해제시
				this.likeCnt = -1;
				this.liked = false;
			}

			this.$store
				.dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
					brcInfoNo: this.brcInfoNo,
					recommCnt: this.likeCnt,
					clientIP: this.clientIp,
					anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
				})
				.then((res) => {
					console.log('res : ' + JSON.stringify(res));
					this.recommCnt = res.data.VIEW.RECOMM_CNT;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		//채팅창 on-off
		chatToggle() {
			//  비로그인 시
			if (this.isMobile && this.useChatYn == 'Y') {
				if (this.isAuth) {
					document.getElementById('livechat-footer').style.display = isChatShow ? 'none' : 'flex';
					isChatShow = !isChatShow;
				} else if (!this.isAuth) {
					this.login();
				}
			}

			if (!this.isMobile) {
				if (isChatShow) {
					//댓글 숨기기
					document.getElementById('chatToggleBtn').innerText = '댓글표시';
					document.getElementById('myplayer').setAttribute('width', 1224);
					if (!this.isMobile) document.getElementById('chatDiv').style.display = 'none';
					document.querySelector('.vod-wrap').classList.add('vod-wrap-large');
					isChatShow = false;
					document.getElementById('chatMsg').blur();
				} else {
					//댓글 표시
					document.getElementById('chatToggleBtn').innerText = '댓글숨기기';
					document.getElementById('myplayer').setAttribute('width', 1024);
					document.getElementById('chatDiv').style.display = 'flex';
					document.querySelector('.vod-wrap').classList.remove('vod-wrap-large');
					isChatShow = true;
					document.getElementById('chatMsg').focus();
				}
			}
		},
		copyUrl() {
			//url 넣을 input 생성
			const urlInput = document.getElementById('urlInput');

			//현재 URL 가져오기
			urlInput.value = window.document.location.href;

			//clipboard 복사
			try {
				urlInput.select();
				document.execCommand('copy');
				// alert(`URL이 복사 되었습니다. : ${urlInput.value}`);
				alert('URL이 복사 되었습니다.');
			} catch (error) {
				console.error(error);
			}
		},
		//채팅메시지 보여주기
		showChatMessage(method, chatObj, config) {
			let temp; // 메세지 Obj 임시 변수
			let msg; // 실제 화면에 노출될 메세지
			let type; // 메세지 Type
			let profileImg;
			console.log('chatObj' + JSON.stringify(chatObj));

			temp = JSON.parse(chatObj.message);
			msg = temp.message;
			type = temp.type;
			profileImg = !temp.profileImg || typeof temp.profileImg == 'undefined' ? defaultProfileImg : temp.profileImg;
			let classType = '';

			if (temp.adminYn == 'Y') {
				classType = 'admin';
			}

			let html = '';

			if (type == '11') {
				// 댓글

				let replyMsg = temp.replyMsg;
				let userNickname = temp.replyUsr;

				// 댓글
				html += ` <div class="msg">
                      <span class="nickname admin">관리자</span>
                      <p class="txt txt-reply">
						<span class="txt-reply-username">${userNickname}</span>
						${replyMsg}
						<span class="txt-reply-admin">${msg}</span>
                      </p>
                      </div>`;

				/* 참고용 일반 채팅메시지 주석처리
				html += `<div class="msg">
                  <div class="profile">
                    <i class="thumb-wrap">
                      <img src="${profileImg}" alt="사진">
                    </i>`;

				if (temp.adminYn == 'Y') {
					html += ` <span class="nickname ${classType}" style="cursor:pointer;">관리자</span></div>
                          <span class="txt" style="cursor: pointer;" >${msg}</span>&nbsp;`;
				} else {
					html += ` <span class="nickname ${classType}" style="cursor:pointer;">${chatObj.nickname}</span></div>
                          <span class="txt" style="cursor: pointer;">${msg}</span>&nbsp;`;
				}

				html += `</div>`;
				*/
			} else if (type == '02') {
				//채팅공지
				if (msg.trim().length > 0) {
					document.getElementById('chatNoticeWrap').style.display = 'block';

					let urlRegex = /(https?:\/\/[^ ]*)/;
					const urls = [];
					let cnt = 0;

					//msg 내 url parsing
					while(msg.match(urlRegex)){
						let url = msg.match(urlRegex)[1];
						const urlParam = { url: url };
						urls.push(urlParam);
						msg = msg.replace(url, `url${cnt}`);
						cnt++;
					}
					
					//parsing 한 url 을 a태그로 변환
					for(let i = 0; i < urls.length; i++){
						msg = msg.replace(`url${i}`, `<a target='_blank' style='color: #ffff00;' href='${urls[i].url}'>${urls[i].url}</a>`);
					}

					//html 형태로 노출
					document.getElementById('chatNoti').innerHTML = msg;
				} else {
					document.getElementById('chatNoticeWrap').style.display = 'none';
				}
			} else if (type == '03') {
				// 라이브추첨 당첨 메세지
				// 댓글
				/*
    		html += ` <div class="msg ${config.settings.userId}">
                    <span class="txt"><span style="padding-right: 5px; color: #F0CA61;">${msg}</span>
                    </div>`;
    		*/
			} else if (type == '04') {
				// 시청 및 채팅 차단
				html += `<div class="msg">
                  <span class="txt" style="cursor: pointer;color: #DB0000;">${msg}</span>
                 </div>`;

				console.log('blckoId : ' + temp.blcokId);
				var elements = document.getElementsByClassName('msg ' + temp.blcokId);
				console.log('elements.length : ' + elements.length);

				for (var i = 0; elements.length > i; i++) {
					elements[i].style.display = 'none';
				}
			} else if (type == '05') {
				// 입장 메시지
				/*

            if(chatObj.userId == "9999") {
                msg = `[비회원]님이 입장하셨습니다.`;
            } else {
                msg = `[${chatObj.nickname}]님이 입장하셨습니다.`;
            }

            // 고객 입장 메시지
            html += ` <div class="msg ${config.settings.userId}">
                    <span class="txt"><span style="padding-right: 5px; color: #61C4BB;">${msg}</span>
                    </div>`;
            */
			} else if (type == '01') {
				// 일반
				html += `<div class="msg ${chatObj.userId}">
                  <div class="profile">
                    <i class="thumb-wrap">
    		              <img src="${profileImg}" alt="사진">
                    </i>`;

				if (temp.adminYn == 'Y') {
					html += ` <span class="nickname ${classType}" style="cursor:pointer;">관리자</span></div>
                          <span class="txt" style="cursor: pointer;" >${msg}</span>&nbsp;`;
				} else {
					html += ` <span class="nickname ${classType}" style="cursor:pointer;">${chatObj.nickname}</span></div>
                          <span class="txt" style="cursor: pointer;">${msg}</span>&nbsp;`;
				}

				html += `</div>`;
			}

			document.getElementById('chatHistory').insertAdjacentHTML('beforeend', html);

			const livechatContnt = document.getElementById('livechat-content');
			livechatContnt.scrollTo({ top: livechatContnt.scrollHeight - 130, behavior: 'smooth' });
		},
	},
};
</script>